import React, { useEffect, useRef } from "react"
import { useCallback, useState } from "react"
import roslib from "roslib"
import TopicEdit from "../components/topicedit"
import { msToStamp, SensorProps } from "../util"


const Cam: React.FC<SensorProps> = ({ ros }) => {
    const [status, setStatus] = useState("")
    const [camStream, setCamStream] = useState<MediaStream>()
    const [started, setStarted] = useState(false)
    const [devices, setDevices] = useState<Array<MediaDeviceInfo>>()
    const [deviceID, setDeviceID] = useState<string>()

    const [rosTopic, setRosTopic] = useState<roslib.Topic>()

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)

    const start = useCallback(async () => {
        // request permissions
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true,
        })
        stream.getTracks().forEach(track => track.stop());

        let devices = await navigator.mediaDevices.enumerateDevices()
        devices = devices.filter(x => x.kind === "videoinput" && x.deviceId !== "")
        setDevices(devices)
        if (devices.length > 0) {
            setDeviceID(devices[0].deviceId)
        }
        setStarted(true)
    }, [])


    useEffect(() => {
        (async () => {
            try {

                if (deviceID == undefined || videoRef.current === null) {
                    return
                }
                setStatus("")

                if (camStream !== undefined) {
                    camStream.getTracks().forEach(track => track.stop());
                }

                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: false,
                    video: {
                        deviceId: {
                            exact: deviceID
                        }
                    },
                })
                setCamStream(stream)
                videoRef.current.srcObject = stream
                videoRef.current.play()
            } catch (error) {
                console.log(error)
                if (error instanceof Error) {
                    setStatus("Error: " + error.name + "|" + error.message + "||" + deviceID)
                } else if (error instanceof OverconstrainedError) {
                    setStatus("DOMException: " + error.name + "|" + error.message + "|" + error.constraint + "||" + deviceID)
                } else {
                    setStatus("ERR: " + error)
                }
            }
        })()
    }, [deviceID, videoRef])


    useEffect(() => {
        // start publisher, recreate 
        if (!started ||
            deviceID == undefined ||
            rosTopic == undefined ||
            videoRef.current === null ||
            canvasRef.current === null
            ) {
            return
        }

        const iRef = setInterval(() => {
            if (!canvasRef.current || !videoRef.current || !rosTopic) {
                return
            }
            canvasRef.current.width = videoRef.current.videoWidth
            canvasRef.current.height = videoRef.current.videoHeight

            const ctx = canvasRef.current.getContext("2d")!
            ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

            const data = canvasRef.current.toDataURL('image/jpeg');
            const msg = new roslib.Message({
                header: {
                    stamp: msToStamp(Date.now()),
                    frame_id: "web_cam"
                },
                format: "jpeg",
                data: data.replace("data:image/jpeg;base64,", "")
            });

            rosTopic.publish(msg);
            console.log("publish", "cam")
        }, 1000 / 10)

        return () => clearInterval(iRef)
    }, [started, deviceID, videoRef, canvasRef, rosTopic])

    return (
        <div>
            <div className="grid">
                <div>
                    <button disabled={started} onClick={start}>Start Cam</button>
                </div>
                <TopicEdit
                    ros={ros}
                    defaultTopic="web/cam"
                    messageType="sensor_msgs/CompressedImage"
                    onTopicChange={setRosTopic}
                />
                <div>
                    <select value={deviceID} onChange={e => setDeviceID(e.target.value)}>
                        {
                            devices &&
                            devices.map(d =>
                                <option selected={d.deviceId === deviceID} value={d.deviceId} key={d.deviceId}>
                                    {d.label}
                                </option>
                            )
                        }
                    </select>
                </div>
            </div>
            <div className="grid">
                <div>
                    <video style={{ width: "100%" }} ref={videoRef} />
                </div>
                <div>
                    {status}
                    <canvas ref={canvasRef} style={{ display: "none" }} />
                </div>
            </div>
        </div>
    )
}

export default Cam;
