import React from "react"


interface FormatTabProps {
    digits: number,
    data: Array<Array<string | number>>
}

const fmt = (digits: number, x: number | string | undefined) => {
    if (x === undefined) {
        return ""
    }
    if (typeof x === "string") {
        return x
    }

    const r = x.toFixed(digits)
    if (x >= 0) {
        return "+" + r
    }
    return r
}

const FormatTab: React.FC<FormatTabProps> = ({ digits, data }) => (
    <table style={{
        fontFamily: "monospace"
    }}>
        <tbody>
            {
                data.map(row => (<tr>
                    {row.map(x => (
                        <td>{fmt(digits, x)}</td>
                    ))}
                </tr>))
            }
        </tbody>
    </table>
)


export default FormatTab