import React from "react"
import { useCallback, useEffect, useState } from "react"
import roslib from "roslib"
import FormatTab from "../components/formattab"
import TopicEdit from "../components/topicedit"
import { msToStamp, SensorProps } from "../util"

const frequency = 60

const Imu: React.FC<SensorProps> = ({ ros }) => {
    const [started, setStarted] = useState(false)

    const [oriError, setOriError] = useState<string>()
    const [aclError, setAclError] = useState<string>()
    const [gyrError, setGyrError] = useState<string>()

    const [rosTopic, setRosTopic] = useState<roslib.Topic>()
    const [msg, setMsg] = useState({
        header: {
            stamp: {},
            frame_id: "web_imu"
        },
        orientation: { x: 0, y: 0, z: 0, w: 0 },
        angular_velocity: { x: 0, y: 0, z: 0, },
        linear_acceleration: { x: 0, y: 0, z: 0, },
    })

    useEffect(() => {
        if (!started || rosTopic === undefined) {
            return
        }
        const pub = () => {

            msg.header.stamp = msToStamp(Date.now())
            const rosMsg = new roslib.Message(msg)
            rosTopic.publish(rosMsg)
            console.log("publish", "imu", msg)
        }

        const i = setInterval(pub, 1000 / frequency)
        return () => clearInterval(i)
    }, [started, rosTopic])

    const start = useCallback(async () => {
        setStarted(true)

        const aos = new AbsoluteOrientationSensor({ frequency });
        aos.addEventListener("reading", () => {
            if (aos.quaternion === undefined) {
                return
            }

            const [x, y, z, w] = aos.quaternion
            const orientation = { x, y, z, w, }
            setMsg(prev => ({ ...prev, orientation }))
        });
        aos.addEventListener("error", (event: SensorErrorEvent) => {
            setOriError(event.error.name)
        })


        const acl = new Accelerometer({ frequency });
        acl.addEventListener("reading", () => {
            if (acl.x === undefined || acl.y === undefined || acl.z === undefined) {
                return
            }

            const linear_acceleration = {
                x: acl.x,
                y: acl.y,
                z: acl.z,
            }
            setMsg(prev => ({ ...prev, linear_acceleration }))

        });
        acl.addEventListener("error", (event: SensorErrorEvent) => {
            setAclError(event.error.name)
        })

        const gyro = new Gyroscope({ frequency });
        gyro.addEventListener('reading', (e) => {
            if (gyro.x === undefined || gyro.y === undefined || gyro.z === undefined) {
                return
            }

            const angular_velocity = {
                x: gyro.x,
                y: gyro.y,
                z: gyro.z,
            }
            setMsg(prev => ({ ...prev, angular_velocity }))
        });
        gyro.addEventListener("error", (event: SensorErrorEvent) => {
            setGyrError(event.error.name)
        })

        aos.start()
        gyro.start()
        acl.start()

    }, [])

    const rowOri = oriError ?
        ["Ori", oriError] :
        ["Ori", msg.orientation.x, msg.orientation.y, msg.orientation.z, msg.orientation.w,]

    const rowAcl = aclError ?
        ["Ori", aclError] :
        ["Acl", msg.linear_acceleration.x, msg.linear_acceleration.y, msg.linear_acceleration.z,]

    const rowGry = gyrError ?
        ["Gry", gyrError] :
        ["Gyr", msg.angular_velocity.x, msg.angular_velocity.y, msg.angular_velocity.z,]

    return (
        <div className="grid">
            <div>
                <button disabled={started} onClick={start}>Start IMU</button>
            </div>
            <TopicEdit
                ros={ros}
                defaultTopic="web/imu"
                messageType="sensor_msgs/Imu"
                onTopicChange={setRosTopic}
            />
            <div>
                {started && (
                    <FormatTab digits={2} data={[
                        rowOri,
                        rowAcl,
                        rowGry,
                    ]} />
                )}
            </div>
        </div>
    )
}

export default Imu;
