import React, { useEffect } from "react"
import { useState } from "react"

interface SetInputProp {
    defaultValue: string
    storageKey: string

    onChange: (value: string) => void
}

export const SetInput: React.FC<SetInputProp> = ({ storageKey, defaultValue, onChange }) => {
    const [value, setValue] = useState<string>(defaultValue)


    useEffect(() => {
        const item = localStorage.getItem(`setting_${storageKey}`)
        if (item !== null) {
            setValue(item)
        }
    }, [])

    useEffect(() => {
        onChange(value)
        localStorage.setItem(`setting_${storageKey}`, value)
    }, [value])

    return (
        <input type="text" placeholder="topic" value={value} onChange={e => setValue(e.target.value)} />
    )
}

interface SetCheckboxProp {
    defaultValue: boolean
    storageKey: string

    onChange: (value: boolean) => void
}

export const SetCheckbox: React.FC<SetCheckboxProp> = ({ storageKey, defaultValue, onChange }) => {
    const [value, setValue] = useState(defaultValue)


    useEffect(() => {
        const item = localStorage.getItem(`setting_${storageKey}`)
        if (item !== null) {
            setValue(JSON.parse(item))
        }
    }, [])

    useEffect(() => {
        onChange(value)
        localStorage.setItem(`setting_${storageKey}`, JSON.stringify(value))
    }, [value])

    return (
        <input type="checkbox" placeholder="topic" checked={value} onChange={e => setValue(e.target.checked)} />
    )
}