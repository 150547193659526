import React, { useCallback, useContext, useEffect, useState } from "react";
import roslib from "roslib"
import { SetCheckbox, SetInput } from "./components/settings";
import { EditCtx } from "./context";
import Cam from "./sensor/cam";
import Imu from "./sensor/imu";
import Location from "./sensor/location"

const App = () => {
    const [editTopic, setEditTopic] = useState(false)
    const [wakeLockStatus, setWakeLockStatus] = useState("")
    const [rosURL, setRosURL] = useState(`${location.protocol === "https:" ? "wss" : "ws"}://${location.hostname}:9090`)
    const [ros, setRos] = useState<roslib.Ros>()
    const [rosStatus, setRosStatus] = useState<string>()

    useEffect(() => {
        console.log("Connecting:", rosURL)
        try {
            const url = new URL(rosURL)
            if (url.protocol !== "wss:" && url.protocol !== "ws:") {
                setRosStatus("URL must be a websocket: 'ws://' or 'wss://'")
                return
            }
        } catch (error) {
            console.log("websocket url parsing error", error)
            setRosStatus("Invalid URL")
            return
        }
        try {
            const ros = new roslib.Ros({
                url: rosURL
            });
            setRosStatus("Connecting...")
            ros.on('connection', function () {
                setRosStatus('Connected');
            });

            ros.on('error', function (error) {
                setRosStatus('Error: ' + error);
            });

            ros.on('close', function () {
                setRosStatus('Closed');
            });

            setRos(ros)

            return () => {
                console.log("Disconected", rosURL)
                ros?.close()
            }
        } catch (error) {
            console.log("ROS connection error:", error)
            setRosStatus("Excpetion")
        }
    }, [rosURL])

    useEffect(() => {
        (async () => {
            try {
                if ('wakeLock' in navigator) {
                    await navigator.wakeLock.request('screen');
                    setWakeLockStatus("WL Active")
                } else {
                    setWakeLockStatus("WL not supported")
                }
            } catch (error) {
                setWakeLockStatus("WL error: " + error)
            }
        })()
    })

    return (<>
        <h1>ROS ref</h1>
        <div className="grid">
            <div>
                <button disabled={true}>{rosStatus}</button>
            </div>
            <div>
                <SetInput defaultValue={rosURL} storageKey="ROS_URL" onChange={setRosURL} />
            </div>
            <div>
                <label >
                    <SetCheckbox
                        defaultValue={editTopic}
                        onChange={setEditTopic}
                        storageKey="editTopic"
                    />
                    Edit mode
                </label>
                {wakeLockStatus}
            </div>
        </div>
        <EditCtx.Provider value={editTopic}>
            <Location ros={ros} />
            <Imu ros={ros} />
            <Cam ros={ros} />
        </EditCtx.Provider>
    </>)
};

export default App;