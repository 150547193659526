export const msToStamp = (ms: number) => {

    const sec = Math.floor(ms / 1000)
    const nanosec = (ms - sec * 1000) * 1000 * 1000
    return {
        sec,
        nanosec,
    }
}

export interface SensorProps {
    ros?: ROSLIB.Ros
}

