import React, { useContext, useEffect, useState } from "react"
import roslib from "roslib"
import { EditCtx } from "../context"
import { SetInput } from "./settings"

interface RowPropts {
    ros?: roslib.Ros
    messageType: string
    defaultTopic: string

    onTopicChange: (topic: roslib.Topic) => void
}

const TopicEdit: React.FC<RowPropts> = ({ ros, messageType, defaultTopic, onTopicChange }) => {
    const [topicName, setNameTopic] = useState(defaultTopic)
    const editTopic = useContext(EditCtx)

    useEffect(() => {
        if (ros === undefined) {
            return
        }
        const topic = new roslib.Topic({
            ros,
            name: topicName,
            messageType
        });

        onTopicChange(topic)
        console.log("Updated topic", topicName)
    }, [topicName, ros])

    if (editTopic) {
        return (
            <SetInput defaultValue={defaultTopic} storageKey={`topic_${defaultTopic}`} onChange={setNameTopic} />
        )
    }

    return (
        <></>
    )
}

export default TopicEdit