import React from "react"
import { useCallback, useEffect, useState } from "react"
import roslib from "roslib"
import FormatTab from "../components/formattab"
import TopicEdit from "../components/topicedit"
import { msToStamp, SensorProps } from "../util"

const Location: React.FC<SensorProps> = ({ ros }) => {
    const [started, setStarted] = useState(false)

    const [error, setError] = useState("")
    const [rosTopic, setRosTopic] = useState<roslib.Topic>()
    const [msg, setMsg] = useState({
        header: {
            stamp: {},
            frame_id: "web_gnss",
        },
        latitude: 0,
        longitude: 0,
        altitude: 0,
    })

    const success = useCallback((pos: GeolocationPosition) => {

        const timestamp = pos.timestamp;
        const latitude = pos.coords.latitude;
        const longitude = pos.coords.longitude;
        let altitude = pos.coords.altitude;
        if (altitude == undefined) {
            altitude = 0.0
        }


        setMsg({
            header: {
                stamp: msToStamp(timestamp),
                frame_id: "web_gnss"
            },
            latitude,
            longitude,
            altitude,
        })
    }, [])

    useEffect(() => {
        if (rosTopic === undefined) {
            return
        }
        const rosMsg = new roslib.Message(msg)
        rosTopic.publish(rosMsg)
        console.log("publish", "location", msg)
    }, [msg])

    const start = useCallback(() => {
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by your browser')
        } else {
            setStarted(true)
            navigator.geolocation.watchPosition(success, e => {
                setError('Unable to retrieve your location' + e.code)
                setStarted(false)
            }, {
                enableHighAccuracy: true,
            })
        }
    }, [])


    return (
        <div className="grid">
            <div>
                <button disabled={started} onClick={start}>Start Pos</button>
            </div>
            <TopicEdit
                ros={ros}
                defaultTopic="web/gnss"
                messageType="sensor_msgs/NavSatFix"
                onTopicChange={setRosTopic}
            />
            {
                error && (
                    <p>{error}</p>
                )
            }
            <div>
                {started && (
                    <FormatTab digits={6} data={[
                        [msg.latitude, msg.longitude, msg.altitude,],
                    ]} />
                )}
            </div>
        </div>
    )
}

export default Location;
